import { Spinner, Center, Flex, Box, Text, HStack } from '@chakra-ui/react';
import { NextPage } from 'next';
import React, { useMemo, useState } from 'react';

import { Swap } from '@app/components/Swap';
import { getTokensWhitelist } from '@app/constants/tokensWhitelist';
import Toggle from '@app/components/Toggle/Toggle';
import { SwapV3 } from '@app/components/SwapV3';
import { useConfig } from '@app/config';
import Metadata from '@app/components/Metadata';
import AnimatedLayout from '@app/layouts/AnimatedLayout/AnimatedLayout';

const VIEWS = [
  {
    label: 'V4',
    value: 'v3'
  },
  {
    label: 'V2',
    value: 'v2'
  }
];

// @ts-ignore
export const SwapPage: NextPage = () => {
  const [view, setView] = useState('v3');
  const config = useConfig();
  const TOKENS_WHITELIST = getTokensWhitelist(config);
  const initialTokensData = useMemo(() => {
    const from = TOKENS_WHITELIST.find(
      t => t.contractAddress === config?.CONTENT.defaultTokens.from
    )!;

    const to = TOKENS_WHITELIST.find(
      t => t.contractAddress === config?.CONTENT.defaultTokens.to
    )!;

    return {
      from: {
        ...from
        // logoUri: getWhitelistedCoinIcon(from)
      },
      to: {
        ...to
        // logoUri: getWhitelistedCoinIcon(to)
      }
    };
  }, [
    TOKENS_WHITELIST,
    config?.CONTENT.defaultTokens.from,
    config?.CONTENT.defaultTokens.to
  ]);

  function renderContent() {
    if (!initialTokensData) {
      return (
        <Flex
          w="100%"
          h="100%"
          minHeight="calc(100vh - 200px)"
          justifyContent="center"
        >
          <Center>
            <Spinner color="orange" width={8} height={8} />
          </Center>
        </Flex>
      );
    }

    if (view === 'v3') {
      return <SwapV3 initialTokensData={initialTokensData} />;
    }

    return <Swap initialTokensData={initialTokensData} />;
  }

  return (
    <AnimatedLayout>
      <Metadata title={'Swap | Kim'} />
      <Box
        padding={[0, '12px']}
        mt={[4, 12]}
        maxWidth="573px"
        mx="auto"
        w="100%"
      >
        <HStack mb={3} justifyContent="space-between" flexWrap="wrap">
          <Text
            fontSize={[24, 32]}
            fontWeight={500}
            lineHeight="32px"
            color="white"
            textAlign="left"
          >
            Swap
          </Text>
          <Toggle
            view={view}
            onChangeView={nextView => setView(nextView)}
            views={VIEWS}
          />
        </HStack>
        {renderContent()}
      </Box>
    </AnimatedLayout>
  );
};

export default SwapPage;
